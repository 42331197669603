import React, { useContext, useEffect } from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Layout from "../components/wrappers/layout"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import SEO from "../components/seo"
import Header from '../components/header';
import TabbedItem from '../components/wrappers/tabbed-item';
import TabbedList from '../components/wrappers/tabbed-list';
import CaseList from '../components/case-list';

import Faq from '../components/faq';
import CardArticle from '../components/card-article';
import { graphql } from 'gatsby';
import Logos from "../components/logos";
import Cta from "../components/cta";
import TabbedServices from "../components/tabbed-services";
import { domain, getRandomIndex, stripString } from "../utils";
import Markdown from "markdown-to-jsx"
import CaseListCarousel from "../components/case-list-carousel";
import Link from "../components/link";
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider";
import Testimonial from "../components/testimonial";
import Media from "../components/media";
import { CustomHr } from "../components/CustomHr";
import TriangleBubbles from "../components/hashTagsTriangles";

const ServiceDetailTechnical = props => {
  const { general, footers, headers, serviceTechnical, servicesPage, theme } = props.data.strapi;
  const { serviceDetailFooterBgColorDarkMode, serviceDetailFooterBgColorLightMode, generalBackgroundColorDarkMode, generalBackgroundColorLightMode, clientLogosColorDarkMode, clientLogosColorLightMode , fontColorDarkMode, fontColorLightMode, hashtagsBackgroundColor, hashtagsBackgroundColorDarkMode, pageTransitionBgColorLightMode, pageTransitionBgColorDarkMode } = general; 
    const { hashtagsSection } = serviceTechnical;

  const language = props.pageContext.language;
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  const copyrightYear = footer.copyrightYear && footer.copyrightYear; 
  const openingHours = (language ==="en") && servicesPage?.openingHours || ""; 
  const breakpoints = useBreakpoint();

  const { hero, darkTheme, mobileHeroBackground ,intro,  faqs, logos, cta1, services, aboutUs, collage1, stats, articles, cta2, copyright, seo, casesSlider, author,serviceEmail, service_technical, testimonials } = serviceTechnical;
  const pageRedirectInfo = service_technical; 
  const redirectTo = pageRedirectInfo?.language && pageRedirectInfo?.slug ? `/${pageRedirectInfo?.language}/services/${pageRedirectInfo?.slug}` : ""; 
  const imageBorderRadiusForServices = general?.imageBorderRadiusForServices; 
  // getting  page first H1 to set as meta title and description if not set in strapi .

  let pageFirstHeading = hero?.header && (hero.header.supheading + " "+ hero.header.heading);
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const darkMode = state.darkModePreference === 'moon';
  const seoTitle = seo && seo.metaData.metaTitle || pageFirstHeading|| "service detail design  page";
  const seoDescription = seo && seo.metaData.metaDescription || pageFirstHeading || "service detail design page";
  const seoKeywords = seo && seo.metaData?.keywords;
  const isSubOpen = state.siteSubNav;
  const switchToDarkMode = theme.switchToDarkMode;
  const primaryBtn = general?.primaryBtn;
  const isMobileView = breakpoints.sm;
  const background = isMobileView && mobileHeroBackground ? mobileHeroBackground :  hero?.background && hero?.background;
  const [backgroundImage, setBackgroundImage] = React.useState("");
  const greySectionLightModeBgColor = general?.greySectionLightModeBgColor; 
  const greySectionDarkModeBgColor = general?.greySectionDarkModeBgColor; 
  const greySectionBgColor = darkMode ? greySectionDarkModeBgColor : greySectionLightModeBgColor; 
  const servicesfooterColor = darkMode ? serviceDetailFooterBgColorDarkMode : serviceDetailFooterBgColorLightMode
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const logosBgColor = darkMode ? clientLogosColorDarkMode : clientLogosColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  const filteredRelatedCards = articles?.relatedCard.filter(relatedCard => !relatedCard?.blog?.inactive || relatedCard?.case_study); 
  const displayedHashtagBgColor = darkMode ? hashtagsBackgroundColorDarkMode : hashtagsBackgroundColor;
  const pageTransitionBgColor = darkMode ? pageTransitionBgColorDarkMode : pageTransitionBgColorLightMode; 

  footer.theme = 'short';
  if (copyright) {
    footer.copyright  = copyright;
  }

  const [anchorLinks, setAnchorLinks] = React.useState([])
  const newAnchorLinks = []; 

  useEffect(() => {
    hero?.header?.anchorText && newAnchorLinks.push({header: hero.header.anchorText  , display: true}); 
    logos?.header?.anchorText && newAnchorLinks.push({header: logos.header.anchorText , display: true}); 
    cta1?.text?.anchorText && newAnchorLinks.push({header: cta1.text.anchorText, display: true}); 
    services?.header?.anchorText && newAnchorLinks.push({header: services.header.anchorText, display: true}); 
    casesSlider?.anchorText && newAnchorLinks.push({header: casesSlider?.anchorText , display: true}); 
    faqs?.header?.anchorText && newAnchorLinks.push({header: faqs.header.anchorText , display: true}); 
    aboutUs?.header.anchorText && newAnchorLinks.push({header: aboutUs.header.anchorText , display: true});
    stats?.header?.anchorText && newAnchorLinks.push({header: stats.header.anchorText, display: true});  
    testimonials?.anchorText && newAnchorLinks.push({header: testimonials?.anchorText, display: true}); 
    articles.anchorText && newAnchorLinks.push({header: articles.anchorText, display: true}); 
    cta2?.anchorText && newAnchorLinks.push({header: cta2.anchorText, display: true}); 
 
    setAnchorLinks(newAnchorLinks);
    const id = "services"; 
    if (!isSubOpen[id]) {
      dispatch({ 
        type: "TOGGLE_SITE_SUB_NAV" , 
        payload: {
          id: id
        } 
      });
    } 
  }, []); 


  useEffect(()=> {
   if (isMobileView) {
    setBackgroundImage(background);
   } else {
    setBackgroundImage(background);
   }
  }, [background]);
  
  const imageExts = ['.svg', '.png', '.gif', '.jpg', '.jpeg'];
  const isImage = backgroundImage && imageExts.includes(backgroundImage.ext);
  
  return (
    <Layout header={header} footer={footer} language={language} serviceEmail={serviceEmail} showWhiteIcon={false} redirectTo={redirectTo} anchorLinks={anchorLinks} isImage={!isImage} servicesfooterColor={servicesfooterColor} isServicesLandingPage={true} logosBgColor={logosBgColor}> 
      <SEO
        websiteFontColor={websiteFontColor} 
        template="service-detail" 
        title={seoTitle} 
        description={seoDescription} 
        seokeywords={seoKeywords} 
        darkTheme={darkTheme} 
        darkMode={darkMode} 
        websiteBackgroundColor={sectionBackgroundColor}
      />
      {hero && (
        <section className="hero hero--white service-detail-design" style={{backgroundColor: hero.backgroundColor}} id={stripString(hero.header.anchorText ) || ''}>
          <div className="container-lg container align-items-end">
            <div className="hero__background hero__background--shadow">
              {backgroundImage && (
                <div className="hero__background-inner">
                  <Media media={backgroundImage} showVideoAutoWith={true} />
                </div>
              )}
            </div>
            <div className="hero__main">
              <div className="row align-items-end">
                <div className="col-12">
                  <div className="hero__content">
                    <header className="hero__header header">
                      <h1 className="header__heading">
                        <small className="header__preheading">
                          {hero.header.supheading}
                        </small>
                        {hero.header.heading}
                      </h1>
                    </header>
                    
                    <div className="hero__body rich-text">
                      <Markdown options={{
                        overrides: {a: {
                          component: Link
                        },                           
                        hr: { 
                            component: CustomHr,
                            props: {
                              borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                            }
                          }  
                        }
                      }}>
                        {hero.body}
                      </Markdown>
                    </div>

                    {hero.links && (
                      <div className="hero__buttons buttons">
                        {hero.links.map((link) => {
                          if (link?.document) {
                            return (
                              <>
                                {link.document && ( 
                                  <a href={`${link.document && link.document.url}`} className="button button--white" target="_blank">
                                    <span className="button__text">{link.label}</span>
                                  </a>
                                )}
                              </>
                            )
                          } else {
                            return (            
                              <Link
                                className="button button--white"
                                to={`${link.path}`}
                              >
                                <span className="button__text">{link.label}</span>
                              </Link>                      
                            )
                          }
      
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <SiteHeaderAlt header={header} serviceEmail={serviceEmail} showWhiteIcon={false} redirectTo={redirectTo} anchorLinks={anchorLinks} servicesfooterColor={servicesfooterColor}>
        {services && (
          <TabbedServices 
            component={services} 
            language={language} 
            shouldNotLazyLoad={true}
            imageBorderRadiusForServices={imageBorderRadiusForServices}
            sectionBackgroundColor={sectionBackgroundColor}
            websiteFontColor={websiteFontColor}
          />
        )}

        {cta1 && (
          <Cta 
            component={cta1} 
            className="cta--wide" 
            showNumber={true} 
            author={author}
            showTeaser={true} 
            openingHour={openingHours}
            shouldNotLazyLoad={true}
            greySectionBgColor={greySectionBgColor}
            fontColorLightMode={websiteFontColor}
          />
        )}
        {(casesSlider && casesSlider.caseStudies.length > 0) && (
          <section 
            className="section cases" 
            id={casesSlider?.anchorText ? stripString(casesSlider?.anchorText) : ''}
            style={{backgroundColor: sectionBackgroundColor}}
          >
            {casesSlider.heading && (
              <Header 
                heading={casesSlider?.heading} 
              />
            )}
            <div className="container">
              {(casesSlider?.caseStudies && casesSlider.caseStudies.length > 0) && (
                <div className="row">
                  <div className="offset-lg-1 col-lg-10">
                    <CaseList
                      caseStudies={casesSlider.caseStudies} 
                      oddElementMarginTop={true}  
                      showCaseIntroText={true}
                      imageBorderRadiusForServices={imageBorderRadiusForServices}
                      hashtagsBackgroundColor={displayedHashtagBgColor}                      
                      websiteFontColor={websiteFontColor}
                      pageTransitionBgColor={pageTransitionBgColor}

                    />
                  </div>
                </div>
              )}
            </div>
          </section>
        )}

        {logos && (
          <Logos component={logos} shouldNotLazyLoad={true} sectionBackgroundColor={sectionBackgroundColor} logosBgColor={logosBgColor} scaleMobileLogos={true} />
        )}


        {aboutUs && (
          <section className="section" id={aboutUs.header.anchorText? stripString(aboutUs.header.anchorText): ''} style={{backgroundColor: sectionBackgroundColor}}>
            {aboutUs.header && (
              <Header heading={aboutUs.header.heading} supheading={aboutUs.header.supheading} />
            )}
            <div className="container">
              <div className="row">
                <div className="offset-md-2 col-md-7 rich-text">
                  <Markdown options={{
                    overrides: {a: {
                      component: Link
                    },                           
                    hr: { 
                        component: CustomHr,
                        props: {
                          borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                        }
                      }  
                    }
                  }}>
                    {aboutUs.body || ''}
                  </Markdown>
                </div>
              </div>
            </div>
            <div className="collage collage--short">
              <div className="container">
                <div className="row">
                  <div className="offset-md-1 col-md-3 collage-item">
                    {collage1[0] && (
                      <img 
                        src={collage1[0].url} 
                        style={{borderRadius: imageBorderRadiusForServices}}
                        alt={collage1[0].alternativeText} 
                      />
                    )}
                  </div>
                  <div className="offset-md-1 col-md-6 collage-item">
                    {collage1[1] && (
                      <img 
                        src={collage1[1].url} 
                        style={{borderRadius: imageBorderRadiusForServices}}
                        alt={collage1[1].alternativeText}  
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}

        {stats && (
          <section 
            className="section section--grey stats" 
            id={stats?.header?.anchorText ? stripString(stats.header.anchorText): ''} 
            style={{backgroundColor: greySectionBgColor}}
          >
            {stats.header && (
              <Header heading={stats.header.heading} supheading={stats.header.supheading} withFullWidth={true}/>
            )}
            
            {(stats.stat && stats.stat.length > 0) && (
              <div className="container">
                <div className="row">
                  <div className="col-12 stats__list">
                    {stats.stat.map((stat) => {
                      if (!stat.value) { return; }
                      return (
                        <div className="stats__stat">
                          <h2>{stat.value.replace('+', '')}{stat.value.includes('+') && (<span>+</span>)}</h2>
                          <h3 style={{color: websiteFontColor }}>{stat.label}</h3>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            )}
          </section>
        )}
        {(faqs && faqs.faqs.length > 0 ) && (
          <section 
            className="section faqs section--grey" 
            id={faqs.header.anchorText ? stripString(faqs.header.anchorText) : ''}
            style={{backgroundColor: greySectionBgColor}}
          >
            {faqs.header && (
              <Header heading={faqs.header.heading} supheading={faqs.header.supheading} />
            )}
            {faqs.faqs && (
              <div className="container">
                <div className="row">
                  {faqs.faqs.map((faq) => {
                    return (
                      <div className="col-tw-12 col-md-9 offset-md-1 col-lg-8 offset-lg-2">
                        <Faq heading={faq.heading} shouldNotLazyLoad={true} showWhitePlus={darkTheme || switchToDarkMode || darkMode} fontColorLightMode={fontColorLightMode} >
                            <Markdown options={{
                              overrides: {a: {
                                component: Link
                              },                           
                              hr: { 
                                component: CustomHr,
                                  props: {
                                    borderBottom: `1px solid ${websiteFontColor}` // Pass borderTop style directly as a prop
                                  }
                                }  
                              }
                            }}>
                            {faq.richText || ''}
                          </Markdown>
                        </Faq>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
          </section>
        )}

        {(testimonials && testimonials?.showTestimonials && testimonials.testimonials?.length) && (
          <section className="section" id={stripString(testimonials.anchorLinks)} style={{backgroundColor: sectionBackgroundColor}}>
            <div className="container">
              <Testimonial 
                testimonialBtnText={primaryBtn}
                title={testimonials.title} 
                testimonials={testimonials.testimonials} 
                imageBorderRadiusForServices={imageBorderRadiusForServices}
                shouldNotLazyLoad={true} 
                logosBgColor={logosBgColor}
                sectionBackgroundColor={sectionBackgroundColor}
              />
            </div>
          </section>
        )}
        {(articles && filteredRelatedCards.length > 0) && (
          <section className="cards-articles section" id={articles.anchorText ? stripString(articles.anchorText) : '' } style={{backgroundColor: sectionBackgroundColor}}>
            <Header heading={articles.heading} supheading={articles.sup} withFullWidth={true} />
            <div className="container">
              <div className="row card-articles">
                {filteredRelatedCards.map((relatedCard, index) => {
                  const article = relatedCard.blog || relatedCard.case_study;
                  return ( 
                    <CardArticle 
                      article={article} 
                      path={relatedCard.blog ? 'blog' : 'case-studies'} 
                      shouldNotLazyLoad={true}
                      imageBorderRadiusForServices={imageBorderRadiusForServices}
                      hashtagsBackgroundColor={displayedHashtagBgColor}
                      sectionBackgroundColor={sectionBackgroundColor}
                    />
                  )
                })}
              </div>
            </div>
          </section>
        )}
        {hashtagsSection && (
          <section className="section">
            <TriangleBubbles  
              hashtagsBackgroundColor={displayedHashtagBgColor} 
              borderRadius={imageBorderRadiusForServices}
              hashtagsSection={hashtagsSection}
            />
          </section>
        )}
        {cta2 && (
          <Cta 
            component={cta2} 
            className="cta--wide" 
            showNumber={true}  
            showTeaser={true} 
            author={author} 
            openingHour={openingHours} 
            anchorId={cta2.anchorText}
            shouldNotLazyLoad={true}
            greySectionBgColor={greySectionBgColor}
            fontColorLightMode={websiteFontColor}
          />
        )}
      </SiteHeaderAlt>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ServiceDetailTechnical($id: ID!, $language: String!) {
    strapi {
      general {
        primaryBtn
        blogsOverviewHeadline
        imageBorderRadiusForServices
        greySectionLightModeBgColor
        greySectionDarkModeBgColor
        serviceDetailFooterBgColorDarkMode
        serviceDetailFooterBgColorLightMode
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        clientLogosColorDarkMode
        clientLogosColorLightMode
        fontColorDarkMode
        fontColorLightMode
        hashtagsBackgroundColor
        hashtagsBackgroundColorDarkMode
        pageTransitionBgColorLightMode
        pageTransitionBgColorDarkMode
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }
      servicesPage {
        openingHours
      }
      theme {
        switchToDarkMode
      }
      serviceTechnical(id: $id) {
        darkTheme
        author {
          name
          picture {
            url
          }
          jobTitle
        }
        hashtagsSection {
          heading
          tags {
            tag
          }
        }
        hero {
          background {
            url
            alternativeText
            mime
            caption
            ext
          }
          header {
            supheading
            heading
            anchorText
          }
          body
          links {
            label
            path
            document {
              url
            }
          }
        }
        mobileHeroBackground {
          url
          alternativeText
          mime
          caption
          ext
        }
        service_technical {
          slug
          language
        }
        intro {
          header {
            supheading
            heading
            anchorText
            logo {
              url
              alternativeText
              mime
              ext
            }
          }
          body
          buttons {
            path
            label
          }
        }
        logos {
          header {
            supheading
            heading
            anchorText
          }
          logos {
            url
            alternativeText
            mime
            ext
          }
          affix
        }
        cta1 {
          text {
            heading
            description
          }
          link {
            label
            path
          }
        }
        services {
          header {
            supheading
            heading
            anchorText
          }
          services {
            heading
            media {
              media {
                url 
              }
            }
            body {
              richText
            }
            link {
              label
              path
            }
          }
        }
        casesSlider {
          heading
          caseStudies {
            ...CardArticleCaseStudy
          }
        }
        faqs {
          header {
            heading
            supheading
            anchorText
          }
          faqs {
            heading
            richText
          }
        }
        aboutUs {
          header {
            supheading
            heading
            anchorText
            logo {
              url
              alternativeText
              mime
              ext
            }
          }
          body
          buttons {
            path
            label
          }
        }
        collage1 {
          url
          alternativeText
          mime
          ext
        }
        stats {
          header {
            supheading
            heading
            anchorText
          }
          stat {
            value
            label
          }
        }
        testimonials {
          title
          showTestimonials
          anchorText
          testimonials {
            quote
            position
            name
            company
            description
            logo {
              url
              alternativeText
              mime
              ext
            }
            media {
              url
              ext
              mime
              alternativeText
            }
          }
        }
        articles {
          sup
          heading
          relatedCard {
            blog {
              ...CardArticleBlog
            }
            case_study {
              ...CardArticleCaseStudy
            }
          }
        }
        cta2 {
          text {
            heading
            description
          }
          link {
            label
            path
          }
          anchorText
        }
        copyright
        seo {
          metaData {
            metaTitle
            metaDescription
            keywords
          }
        }
        serviceEmail
      }
  
    }
    thumbrow: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 239, height: 239, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
    category: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 655, height: 369, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
    logo: file(relativePath: {eq: "placeholder.jpg"}) {
      childImageSharp {
        fixed(width: 155, height: 105, cropFocus: CENTER) {
          srcSetWebp
          src
        }
      }
    }
  }
`

export default React.memo(ServiceDetailTechnical);
